<template>
  <div class="about">
    <h1>The Game</h1>
  </div>
</template>

<style scoped>
.about {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

h1 {
  color: blueviolet;
}
</style>
